import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, Experience, AboutUs } from '../components';
import { MainLayout } from '../layouts';
import { AboutUsImageQuery } from '../shared/interfaces';

const AboutUsPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<AboutUsImageQuery>(graphql`
        query {
            aboutUsBackgroundImage: file(name: {eq: "homePageBackgroundImage2"}, extension: {eq: "jpeg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | About Us"/>
            <GeneralJumbotron
                title="About Us"
                backgroundImageURL={queryResult?.aboutUsBackgroundImage.publicURL}
                backgroundText="About Us"
            />
            <AboutUs/>
            <Experience/>
        </MainLayout>
    );
};

export default AboutUsPage;
